// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/index.tsx");
  import.meta.hot.lastModified = "1724962854544.6423";
}
// REMIX HMR END

import { json } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import classNames from "classnames";
import { api } from "~/api";
import { Icon } from "~/components/icon";
import { isUserAuthed } from "~/models/session.server";
export const meta = () => {
  return [{
    title: "Feedgenie"
  }, {
    name: "description",
    content: "All in one news and data aggregator for traders"
  }];
};
export const loader = async ({
  request
}) => {
  const topTickers = await api("prices/top_tickers");
  const isLoggedIn = await isUserAuthed(request);
  return json({
    topTickers,
    isLoggedIn
  });
};
export default function LandingPage() {
  _s();
  const {
    topTickers,
    isLoggedIn
  } = useLoaderData();
  return <div className="lp-bg">
      <div className="space-beween mx-auto flex h-auto max-w-[1600px] items-start p-6 pt-12 text-white md:h-screen md:justify-center md:px-16 md:pt-36">
        <div className="md:w-1/2">
          <div className="mb-6 flex items-center justify-center gap-2 text-sm md:mb-8 md:justify-start md:text-base">
            <div className="uppercase text-gray-400">
              Created by respected traders
            </div>
            <Icon name="verified" />
          </div>

          <div className="mb-8">
            <img src="/images/logo-lp.svg" alt="feedgenie-logo" />
          </div>

          <div className="mb-3 rounded-lg bg-[#191924] p-3 md:hidden">
            <img src="/images/fg-hero.png" alt="feedgenie-screenshot" className="rounded-lg" />
          </div>

          <div className="mb-6 text-center text-base uppercase md:mb-8 md:text-left md:text-xl">
            all in one news and data aggregator for traders
          </div>

          <div className="mb-6 flex justify-center gap-4 md:mb-8 md:justify-start">
            {isLoggedIn ? <Link to="/dashboard" className="rounded-lg bg-watchlist-title px-5 py-3 uppercase text-black">
                Go to dashboard
              </Link> : <>
                <Link to="/signup" className="rounded-lg bg-watchlist-title px-5 py-3 uppercase text-black">
                  Sign up
                </Link>
                <Link to="/login" className="rounded-lg border border-gray-500 px-5 py-3 uppercase text-white">
                  Sign in
                </Link>
              </>}
          </div>

          <div className="grid grid-cols-1 gap-2 md:mt-24 lg:max-w-2xl lg:grid-cols-2 lg:gap-4 xl:grid-cols-3">
            {topTickers?.top_tickers.map(({
            ticker,
            name,
            price,
            change
          }, i) => <div key={i} className="md:max-w-52 mb-2 flex w-full justify-between rounded-lg border border-[#2D2D2D] bg-[#19192B] p-2 md:mb-0 md:block">
                  <div className="flex flex-col md:mb-3">
                    <div>{ticker}</div>
                    <div className="mt-auto text-sm text-gray-400 md:mt-0">
                      {name}
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
                    <div className="text-lg">${price.toFixed(2)}</div>
                    <div className={classNames("self-end", {
                "text-green-500": Math.sign(change) > 0,
                "text-red-500": Math.sign(change) < 0
              })}>
                      {change.toFixed(2)}%
                    </div>
                  </div>
                </div>)}
          </div>
        </div>

        <div className="hidden rounded-lg bg-[#191924] p-4 md:block md:w-1/2">
          <img src="/images/fg-hero.png" alt="feedgenie-screenshot" className="rounded-lg" />
        </div>
      </div>
    </div>;
}
_s(LandingPage, "9N44sZeQkr9kHPQUJnlqJzGog6k=", false, function () {
  return [useLoaderData];
});
_c = LandingPage;
var _c;
$RefreshReg$(_c, "LandingPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;